import React from 'react';
import QuestionIcon from 'assets/QuestionIcon';
import { IconButton } from 'components/GenericStyles/generic.styles';
import { ThemeColors } from 'styles/constants';
import { HelpIconSectionWrapper } from './helpButton.styles';

interface props {
  openHelpModal: () => void;
  isLightMode?: boolean;
}
const HelpButton = (props: props) => {
  return (
    <HelpIconSectionWrapper>
      <IconButton data-testid="help-button" onClick={() => props.openHelpModal()} tabIndex={-1}>
        <QuestionIcon color={props.isLightMode ? ThemeColors.LightGray : ThemeColors.DarkGray} />
      </IconButton>
    </HelpIconSectionWrapper>
  );
};

export default HelpButton;
