import styled from 'styled-components';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

interface ButtonProps {
  margin?: string;
  $isLoading?: boolean;
}

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 32px;
  height: 64px;
  max-width: 544px;
  width: 100%;

  @media (min-width: ${TabletWidth}) {
    min-width: 250px;
    width: fit-content;
    padding: 0 32px;
  }

  @media (min-width: ${DesktopWidth}) {
    min-width: 544px;
  }
`;

export const IconButton = styled.button<{ padding?: string }>`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const PrimaryButtonStyles = styled(BaseButton)<ButtonProps>`
  border: none;
  background-color: ${ThemeColors.TassoBrightBlue};
  color: ${ThemeColors.TassoWhite};
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.$isLoading && `background-color: ${ThemeColors.TassoViolet};`}

  &:disabled {
    background-color: ${ThemeColors.CloudyGray};
  }

  &:hover:enabled {
    cursor: pointer;
    background-color: ${ThemeColors.TassoViolet};
  }
`;

export const SecondaryButtonStyles = styled(BaseButton)<ButtonProps>`
  border: 2px solid ${ThemeColors.TassoViolet};
  background-color: ${ThemeColors.TassoWhite};
  color: ${ThemeColors.TassoBlue};

  &:hover {
    cursor: pointer;
    background-color: ${ThemeColors.TassoLightViolet};
  }
`;

// Panel Container
interface PanelContainerProps {
  padding?: string;
  $justifyContent?: string;
}
export const PanelContainerStyles = styled.div<PanelContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.$justifyContent ?? 'space-between'};
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  appearance: none;
  box-sizing: border-box;
  ${(p) => p.padding && `padding: ${p.padding}`}
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${TabletWidth}) {
    align-items: center;
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  color: ${ThemeColors.CloudyGray};
  align-items: center;
  gap: 4px;
`;

// Tip Components
export const TipWrapper = styled.div`
  background-color: ${ThemeColors.LightGray};
  border-radius: 16px;
  padding: 16px;
  margin: 0px 0px 8px 0px;
  height: fit-content;

  @media (min-width: ${DesktopWidth}) {
    margin-bottom: 16px;
  }
`;

export const TipWithIconWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

interface TipIconProps {
  $titleIconPosition: boolean;
}

export const TipIconWrapper = styled.div<TipIconProps>`
  display: flex;
  flex-direction: ${(props) => (props.$titleIconPosition ? 'row-reverse' : 'row')};
  justify-content: ${(props) => (props.$titleIconPosition ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

export const TipIconStyles = styled.div<TipIconProps>`
  height: 16px;
  width: 16px;
  margin: ${(props) => (props.$titleIconPosition ? '0px 4px 0px 0px' : '0px 0px 0px 4px')};

  img {
    height: 16px;
    width: 16px;
  }
`;
