import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';
import type { TypeTipsSkeleton } from './TypeTips';

export interface TypeRegistrationPanelFields {
  titleText?: EntryFieldTypes.Symbol;
  inputInstructions?: EntryFieldTypes.Symbol;
  errorText?: EntryFieldTypes.Symbol;
  tips?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeTipsSkeleton>>;
  primaryButtonText?: EntryFieldTypes.Symbol;
  nextButtonText: EntryFieldTypes.Symbol;
}

export type TypeRegistrationPanelSkeleton = EntrySkeletonType<TypeRegistrationPanelFields, 'registrationPanel'>;
export type TypeRegistrationPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeRegistrationPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeRegistrationPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeRegistrationPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'registrationPanel';
}

export type TypeRegistrationPanelWithoutLinkResolutionResponse = TypeRegistrationPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeRegistrationPanelWithoutUnresolvableLinksResponse = TypeRegistrationPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeRegistrationPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> =
  TypeRegistrationPanel<'WITH_ALL_LOCALES', Locales>;
export type TypeRegistrationPanelWithAllLocalesAndWithoutLinkResolutionResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeRegistrationPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeRegistrationPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeRegistrationPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
