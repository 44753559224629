import styled from 'styled-components';
import { DesktopWidth } from 'styles/constants';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(3, 17, 55, 0.7), rgba(3, 17, 55, 0));
  padding: 16px;
  height: 112px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;

  @media (min-width: ${DesktopWidth}) {
    background: transparent;
    grid-column: 1 / span 2;
    grid-row: 1;
    height: 100%;
    position: initial;
    padding: 16px 32px;
  }
`;
