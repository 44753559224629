import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';
import type { TypePhaseSkeleton } from './TypePhase';
import type { TypeTipsSkeleton } from './TypeTips';

export interface TypeVideoFields {
  name?: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Text;
  subText?: EntryFieldTypes.Text;
  video?: EntryFieldTypes.AssetLink;
  next?: EntryFieldTypes.EntryLink<TypePhaseSkeleton | TypeVideoSkeleton>;
  progressBarTitle: EntryFieldTypes.Text;
  timerTime?: EntryFieldTypes.Symbol;
  timerButtonText?: EntryFieldTypes.Symbol;
  timerStopText?: EntryFieldTypes.Symbol;
  timerDoneText?: EntryFieldTypes.Symbol;
  showCollectionTimestamp?: EntryFieldTypes.Boolean;
  playbackIcons?: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  tips?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeTipsSkeleton>>;
  videoThumbnail: EntryFieldTypes.AssetLink;
  timerSeconds?: EntryFieldTypes.Integer;
  canPauseTimer?: EntryFieldTypes.Boolean;
  nextButtonText: EntryFieldTypes.Symbol;
}

export type TypeVideoSkeleton = EntrySkeletonType<TypeVideoFields, 'video'>;
export type TypeVideo<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeVideoSkeleton,
  Modifiers,
  Locales
>;

export function isTypeVideo<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeVideo<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'video';
}

export type TypeVideoWithoutLinkResolutionResponse = TypeVideo<'WITHOUT_LINK_RESOLUTION'>;
export type TypeVideoWithoutUnresolvableLinksResponse = TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeVideoWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeVideo<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeVideoWithAllLocalesAndWithoutLinkResolutionResponse<Locales extends LocaleCode = LocaleCode> =
  TypeVideo<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeVideoWithAllLocalesAndWithoutUnresolvableLinksResponse<Locales extends LocaleCode = LocaleCode> =
  TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
