import React from 'react';

import { StepWrapper } from 'components/MapPanel/mapPanel.styles';
import StepsProgressBar from 'components/SharedComponents/StepsProgressBar';
import { TypeVideoWithoutUnresolvableLinksResponse } from 'contentmodels';
import { HeaderWrapper } from './header.styles';

const VideoHeader = (props: {
  panel: TypeVideoWithoutUnresolvableLinksResponse;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  openHelpModal: () => void;
  isLightMode: boolean;
}) => {
  return (
    <HeaderWrapper>
      <StepWrapper>
        <StepsProgressBar
          {...props}
          phaseName={props.panel.fields.progressBarTitle}
          openHelpModal={props.openHelpModal}
          isLightMode={props.isLightMode}
        />
      </StepWrapper>
    </HeaderWrapper>
  );
};

export default VideoHeader;
