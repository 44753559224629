import * as contentful from 'contentful';
declare const VITE_PREVIEW_KEY: string;

const previewKey = VITE_PREVIEW_KEY;
const usePreviewAPI = previewKey.length > 0 && window.location.href.includes('preview=true');

// These aren't secrets because anyone with access to the app can see them in the network tab or source code.
// In the future, we may want to add a service of our design that takes Recaptcha tokens and/or activation codes to proxy content access.
// Here are some ideas around this: https://www.contentful.com/developers/docs/tutorials/general/embargoed-assets-getting-started/
// Also, these tokens are read only and our content isn't particularly useful or valuable outside of our ecosystem.
// As a small precaution I tucked the preview api key into conditional compilation via vite.config.ts so it won't be in the source code for prod builds. See vite.config.ts for details.
export const contentfulClient = contentful.createClient({
  space: 'zvw5uet0c8kl',
  accessToken: usePreviewAPI ? previewKey : '_b1TQwM6Iouo_jBLO6JRqZlSSn-r40dTZ_eHB3uW1UI',
  host: usePreviewAPI ? 'preview.contentful.com' : undefined,
});
