import React from 'react';

import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  BorderLinearProgress,
  ProgressBarWrapper,
  ProgressPhaseWrapper,
  ProgressBarTextWrapper,
  LabelledProgressBarWrapper,
  ProgressBarContainer,
} from './stepsProgressBar.styles';
import HelpButton from '../HelpButton';

interface props {
  phaseName: string;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  openHelpModal: () => void;
  isLightMode?: boolean;
}

const StepsProgressBar = (props: props) => {
  const getProgressBarValue = (index: number) => {
    const toLowerPhases = props.phases.map((phase) => phase.toLowerCase());

    if (toLowerPhases.indexOf(props.phaseName.toLowerCase()) > index) {
      return 100;
    }
    if (toLowerPhases.indexOf(props.phaseName.toLowerCase()) === index) {
      return (props.progressBarStepNum / props.progressBarStepTotal) * 100;
    }
    return 0;
  };

  return (
    <ProgressBarContainer>
      <LabelledProgressBarWrapper>
        <ProgressBarWrapper key={props.progressBarStepNum}>
          {new Array(props.phases.length).fill(null).map((_: string, index: number) => (
            <ProgressPhaseWrapper key={index}>
              <BorderLinearProgress
                variant="determinate"
                value={getProgressBarValue(index)}
                $isLightMode={props.isLightMode}
              />
            </ProgressPhaseWrapper>
          ))}
        </ProgressBarWrapper>
        <ProgressBarTextWrapper>
          <ThemeFonts.LabelSmall color={props.isLightMode ? ThemeColors.TassoWhite : ThemeColors.TassoBlue}>
            {props.phaseName}
          </ThemeFonts.LabelSmall>
          <ThemeFonts.BodySmall
            color={props.isLightMode ? ThemeColors.TassoWhite : ThemeColors.TassoBlue}
          >{`${props.progressBarStepNum} of ${props.progressBarStepTotal}`}</ThemeFonts.BodySmall>
        </ProgressBarTextWrapper>
      </LabelledProgressBarWrapper>
      <HelpButton {...props} />
    </ProgressBarContainer>
  );
};

export default StepsProgressBar;
