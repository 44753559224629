import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { DateTime } from 'luxon';
import { useWakeLock } from 'react-screen-wake-lock';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';

import ReplayIcon from 'assets/ReplayIcon';
import RunningTimerIcon from 'assets/RunningTimerIcon';
import SpeakerIcon from 'assets/SpeakerIcon';
import SpeakerMutedIcon from 'assets/SpeakerMutedIcon';
import TimerIcon from 'assets/TimerIcon';
import LoadingPanel from 'components/SadPanels/LoadingPanel';
import NextButton from 'components/SharedComponents/NextButton';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { useMediaQueries } from 'utils/helpers/mediaQueries';
import { httpRequestHeaders, PATIENT_API_URL } from 'utils/helpers/patientApi';
import VideoHeader from './VideoHeader';
import {
  VideoWrapper,
  VideoPanelBodyContainer,
  VideoPlayerWrapper,
  TimerButtonStyles,
  TimerProgressWrapper,
  TimerIconWrapper,
  TimerProgress,
  ButtonTip,
  RunningTimerButtonText,
  TimerButtonText,
  ReplayButton,
  MuteButton,
  RunningTimerIconWrapper,
  DateTimeWrapper,
  CollectionTimeStampWrapper,
  VideoFooterContainer,
  VideoPanelContainerStyles,
  VideoTextWrapper,
} from './videoPanel.styles';
import VideoTips from './VideoTips';
import { TypeVideoWithoutUnresolvableLinksResponse } from '../../contentmodels';

interface CreateEvent {
  activationCode: string;
  docType: string;
  moduleType: string;
  formData: object;
  createdAt: string | undefined;
}
type VideoPlayState = { muted: boolean; playing: boolean; currentTime: number | undefined; autoPlayTries: number };
const AUTOPLAY_RETRY_MAX_COUNT = 2;

const VideoPanel = (props: {
  panel: TypeVideoWithoutUnresolvableLinksResponse;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  order: number;
  openHelpModal: () => void;
  setIsPanelSwipable: (isPanelSwipable: boolean) => void;
}) => {
  const {
    titleText,
    nextButtonText,
    subText,
    tips,
    video,
    videoThumbnail,
    showCollectionTimestamp,
    timerDoneText,
    timerStopText,
    timerButtonText,
    timerSeconds,
    canPauseTimer,
  } = props.panel.fields;
  const swiper = useSwiper();

  // Timer state
  const [seconds, setSeconds] = useState<number>(timerSeconds!);
  const [hasTimerStarted, setHasTimerStarted] = useState<boolean>(false);
  const [displayTime, setDisplayTime] = useState<string>('');
  const [showTimerDoneText, setShowTimerDoneText] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [loadVideo, setLoadVideo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  // Video state
  const [playState, setPlayState] = useState<VideoPlayState>({
    autoPlayTries: 0,
    currentTime: 0,
    muted: false,
    playing: false,
  });

  // Global store
  const { collectionDateTime, setCollectionDateTime } = useGlobalStore();
  const timeZoneAbbreviation = collectionDateTime?.toFormat('ZZZZ').split(' ').pop();
  const { isDesktopWidth } = useMediaQueries();
  const { request, release } = useWakeLock();

  const secondsToTime = (seconds: number): string => {
    const m = Math.floor((seconds % 3600) / 60).toString();
    const s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');

    return m + ':' + s;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (hasTimerStarted && !isPaused && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setShowTimerDoneText(true);
      // Record timestamp
      handleSubmitCollectionTimestamp();
      // Let go of wake lock
      release();
    }

    const timeInString = secondsToTime(seconds);
    setDisplayTime(timeInString);

    return () => clearInterval(interval);
  }, [hasTimerStarted, isPaused, seconds]);

  const handleEvent = () => {
    setLoadVideo(Math.abs(swiper.activeIndex - props.order) <= 1);
    manageVideoPlayPause();
  };

  useEffect(() => {
    window.addEventListener('popstate', handleEvent);
    return () => window.removeEventListener('popstate', handleEvent);
  });

  useEffect(() => {
    // In case someone refreshes this page directly, make sure video gets loaded
    setLoadVideo(Math.abs(swiper.activeIndex - props.order) <= 1);

    // Otherwise, allow swiper to get the video going.
    // Tie to slideChangeTransitionStart specifically to have greater success on autoPlay + audio unmuted
    // Found older Safari worked better with Start vs End
    swiper.on('slideChangeTransitionStart', function () {
      setLoadVideo(Math.abs(swiper.activeIndex - props.order) <= 2);
      manageVideoPlayPause();
    });
  }, [swiper]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.muted = playState.muted;
    if (playState.currentTime != undefined) {
      video.currentTime = playState.currentTime;
    }

    if (playState.playing && playState.autoPlayTries < AUTOPLAY_RETRY_MAX_COUNT) {
      video.play().catch((error: any) => {
        Sentry.captureException(error);
        setPlayState({
          autoPlayTries: playState.autoPlayTries + 1,
          muted: true, // Mute to improve chance of autoplay
          currentTime: undefined,
          playing: playState.playing,
        });
      });
    } else if (!playState.playing) {
      video.pause();
    }
  }, [playState]);

  // If I am active page, play video
  // If I am inactive page, pause video
  const manageVideoPlayPause = () => {
    if (swiper.activeIndex != props.order) {
      const video = videoRef.current;
      if (video) {
        video.pause();
        return;
      }
    }
    if (swiper.activeIndex === props.order) {
      const currVideo = videoRef.current;
      if (!currVideo) {
        return;
      }
      setPlayState({ autoPlayTries: 0, muted: false, currentTime: 0, playing: true });
    } else {
      setPlayState({ autoPlayTries: 0, muted: playState.muted, currentTime: 0, playing: false });
    }
  };

  const videoRef = useRef<any>(null);
  const rePlayVideo = () => {
    setPlayState({ autoPlayTries: 0, muted: playState.muted, currentTime: 0, playing: true });
  };

  const getMuteButton = () => {
    if (playState.muted) {
      return <SpeakerMutedIcon />;
    }
    return <SpeakerIcon />;
  };

  const handleTimerClick = () => {
    const canSkipTimer = !canPauseTimer;
    if (!hasTimerStarted) {
      setHasTimerStarted(true);
      request(); // wake lock to prevent device sleep
    } else if (hasTimerStarted && canPauseTimer) {
      // handle pause
      setIsPaused((prev) => !prev);
    } else if (hasTimerStarted && canSkipTimer) {
      // show completed text
      swiper.slideNext();
      // Record timestamp
      handleSubmitCollectionTimestamp();
    }
  };

  const handleSubmitCollectionTimestamp = async () => {
    const activationCode = localStorage.getItem('activationCode');
    // If we don't have an activation code, we don't want to hit the API
    if (!activationCode) {
      props.setIsPanelSwipable(true);
      return;
    }
    try {
      setCollectionDateTime(DateTime.now());
      props.setIsPanelSwipable(true);

      const body: CreateEvent = {
        activationCode,
        docType: 'event',
        moduleType: 'dateTimeCollection',
        formData: { dateTimeCollection: new Date().toISOString() },
        createdAt: undefined,
      };

      setIsLoading(true);
      const headers = httpRequestHeaders({});
      const response = await fetch(`${PATIENT_API_URL}/events`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      });

      if (response.ok) {
        const responseBody = await response.json();
        setIsLoading(false);
        return responseBody as CreateEvent;
      } else {
        console.error('Something went wrong. Please try again later.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Something went wrong. Please try again later.', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Handle forever video autoloop
    const myVideo = videoRef.current;

    if (myVideo) {
      const handleEnded = () => {
        // Rewind the video to the start and play it again
        if (!isTouched) {
          setPlayState({ autoPlayTries: 0, muted: true, currentTime: 0, playing: true });
        } else {
          setPlayState({ autoPlayTries: 0, muted: playState.muted, currentTime: 0, playing: true });
        }
      };

      myVideo.addEventListener('ended', handleEnded);

      // Clean up the event listener on component unmount
      return () => {
        myVideo.removeEventListener('ended', handleEnded);
      };
    }
  }, [isTouched, loadVideo, playState]);

  // Purely for the case where the slide is directly loaded by URL and not through swipes.
  useEffect(() => {
    if (loadVideo) {
      manageVideoPlayPause();
    }
  }, [loadVideo]);

  const handleMuteClicked = () => {
    setPlayState({ autoPlayTries: 0, muted: !playState.muted, currentTime: undefined, playing: playState.playing });
    setIsTouched(true);
  };

  return (
    <VideoPanelContainerStyles>
      {isLoading && <LoadingPanel openHelpModal={props.openHelpModal} />}
      {!isLoading && (
        <>
          {isDesktopWidth && <VideoHeader {...props} isLightMode={false} />}
          <VideoWrapper>
            {!isDesktopWidth && <VideoHeader {...props} isLightMode={true} />}
            {loadVideo && (
              <VideoPlayerWrapper>
                <video
                  onClick={rePlayVideo}
                  ref={videoRef}
                  poster={videoThumbnail?.fields.file?.url}
                  muted={playState.muted}
                  autoPlay={false}
                  loop={false}
                  playsInline={true}
                >
                  <source src={video?.fields.file?.url} type={'video/mp4'} />
                  <track default kind="captions" srcLang="en" src={'none'} />
                </video>
                <>
                  <ReplayButton data-testid="replay-button" onClick={rePlayVideo}>
                    <ReplayIcon />
                  </ReplayButton>
                  <MuteButton data-testid="mute-button" onClick={() => handleMuteClicked()} $muted={playState.muted}>
                    {getMuteButton()}
                  </MuteButton>
                </>
              </VideoPlayerWrapper>
            )}
          </VideoWrapper>
          {!showTimerDoneText && (
            <VideoPanelBodyContainer>
              <VideoTextWrapper>
                {titleText && <ThemeFonts.HeadlineSmall>{titleText}</ThemeFonts.HeadlineSmall>}
                {subText && <ThemeFonts.BodyLarge>{subText}</ThemeFonts.BodyLarge>}
              </VideoTextWrapper>
              {showCollectionTimestamp && collectionDateTime && (
                <CollectionTimeStampWrapper data-testid="collection-date-time">
                  <DateTimeWrapper>
                    <ThemeFonts.LabelMedium>Time</ThemeFonts.LabelMedium>
                    <ThemeFonts.HeadlineSmall>{`${collectionDateTime
                      .toFormat('h:mm a')
                      .toLowerCase()} ${timeZoneAbbreviation}`}</ThemeFonts.HeadlineSmall>
                  </DateTimeWrapper>
                  <DateTimeWrapper>
                    <ThemeFonts.LabelMedium>Date</ThemeFonts.LabelMedium>{' '}
                    <ThemeFonts.HeadlineSmall>{collectionDateTime.toFormat('MMM dd, yyyy')}</ThemeFonts.HeadlineSmall>
                  </DateTimeWrapper>
                </CollectionTimeStampWrapper>
              )}
              {tips && tips.map((tip, index: number) => <VideoTips key={index} tip={tip} index={index} />)}
            </VideoPanelBodyContainer>
          )}
          {showTimerDoneText && (
            <VideoPanelBodyContainer>
              <ThemeFonts.HeadlineSmall style={{ padding: '24px 0px' }}>{timerDoneText}</ThemeFonts.HeadlineSmall>
            </VideoPanelBodyContainer>
          )}
          <VideoFooterContainer>
            {!showTimerDoneText && timerButtonText && timerSeconds && (
              <TimerButtonStyles data-testid="timer-button" onClick={handleTimerClick} $isActive={hasTimerStarted}>
                {hasTimerStarted && displayTime && (
                  <TimerProgressWrapper>
                    <TimerProgress $isActive={hasTimerStarted} $timerSeconds={timerSeconds} $isPaused={isPaused}>
                      <RunningTimerButtonText>
                        <RunningTimerIconWrapper>
                          <RunningTimerIcon />
                        </RunningTimerIconWrapper>
                        <ThemeFonts.DisplaySmall data-testid="timer-time">{displayTime}</ThemeFonts.DisplaySmall>
                      </RunningTimerButtonText>
                    </TimerProgress>
                    {timerStopText && (
                      <ButtonTip $isTextLong={timerStopText.length > 15}>
                        <ThemeFonts.TitleMedium color={ThemeColors.TassoDarkBlue}>
                          {!isPaused && timerStopText}
                          {isPaused && 'Tap to resume'}
                        </ThemeFonts.TitleMedium>
                      </ButtonTip>
                    )}
                  </TimerProgressWrapper>
                )}

                {!hasTimerStarted && (
                  <TimerButtonText>
                    <TimerIconWrapper>
                      <TimerIcon />
                    </TimerIconWrapper>
                    <ThemeFonts.LabelLarge>{timerButtonText}</ThemeFonts.LabelLarge>
                  </TimerButtonText>
                )}
              </TimerButtonStyles>
            )}
            {(!timerButtonText || showTimerDoneText) && <NextButton nextButtonText={nextButtonText} />}
          </VideoFooterContainer>
        </>
      )}
    </VideoPanelContainerStyles>
  );
};

export default VideoPanel;
