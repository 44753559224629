import styled from 'styled-components';
import { PanelContainerStyles, TipWrapper } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

// Container with unique breakpoint so the video fits on laptop sizes
export const VideoPanelContainerStyles = styled(PanelContainerStyles)`
  gap: 0;

  @media (min-width: ${DesktopWidth}) {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 88px 75% 112px;
    column-gap: 16px;
  }

  @media (min-width: 1260px) {
    grid-template-columns: 55% 45%;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 50% 50%;
  }
`;

export const VideoPanelBodyContainer = styled.div`
  padding: 0px 16px;
  flex: 1;

  @media (min-width: ${TabletWidth}) {
    max-width: 640px;
    margin: auto;
  }

  @media (min-width: ${DesktopWidth}) {
    max-width: 544px;
    padding-right: 32px;
    margin: 0;
    grid-row: 2;
    align-self: center;
  }
`;

export const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0;

  @media (min-width: ${DesktopWidth}) {
    gap: 24px;
    padding: 0 0 40px;
  }
`;

export const VideoFooterContainer = styled.div`
  padding: 16px;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }

  @media (min-width: ${DesktopWidth}) {
    grid-column: 1 / span 2;
    grid-row: 3;
  }
`;

export const VideoWrapper = styled.div`
  height: 50%;
  position: relative;

  @media (min-width: ${DesktopWidth}) {
    height: 100%;
    max-width: 684px;
    max-height: 684px;
    grid-row: 2;
    justify-self: end;
    align-self: center;
  }
`;

interface MuteButtonProps {
  $muted: boolean;
}

export const MuteButton = styled.button<MuteButtonProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  height: 48px;
  width: 48px;
  margin-left: 84px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0px 1px 10px 0px ${ThemeColors.TassoBlack}26;
  background-color: ${(props) => (props.$muted ? ThemeColors.CloudyGray : ThemeColors.TassoWhite)};

  svg {
    padding-top: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ReplayButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  height: 48px;
  width: 48px;
  margin-left: 16px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0px 1px 10px 0px ${ThemeColors.TassoBlack}26;
  background-color: ${ThemeColors.TassoWhite};

  svg {
    padding-top: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const VideoPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

// Timer Components
interface TimerButtonProps {
  $isActive: boolean;
}

export const TimerButtonStyles = styled.div<TimerButtonProps>`
  text-align: center;
  border-radius: 40px;
  height: 64px;
  font-size: 20px;
  color: ${ThemeColors.TassoWhite};
  height: 64px;
  position: relative;
  ${(props) => props.$isActive && `border: 2px solid ${ThemeColors.TassoViolet}`};
  background-color: ${(props) => (props.$isActive ? ThemeColors.TassoWhite : ThemeColors.TassoBrightBlue)};
  overflow: hidden;

  :hover {
    ${(props) => !props.$isActive && `background-color: ${ThemeColors.TassoViolet}`};
    cursor: pointer;
  }

  @media (min-width: ${TabletWidth}) {
    max-width: 360px;
    margin: auto;
  }

  @media (min-width: ${TabletWidth}) {
    min-width: 544px;
    max-width: 544px;
  }
`;

export const TimerIconWrapper = styled.div`
  svg {
    height: 30px;
    width: 30px;
    padding-top: 4px;
    gap: 4px;
  }
`;

export const RunningTimerIconWrapper = styled.div`
  svg {
    height: 30px;
    width: 30px;
    padding-top: 4px;
    gap: 4px;
    color: ${ThemeColors.TassoBlue};
  }
`;

export const TimerButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px 18px 12px;
`;

export const RunningTimerButtonText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: start;
  padding-left: 12px;
`;

export const TimerProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

interface TimerTipProps {
  $isTextLong: boolean;
}

export const ButtonTip = styled.div<TimerTipProps>`
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  width: 147px;
  height: ${(props) => (props.$isTextLong ? '44px' : '20px')};
  margin: auto;
  text-align: right;

  @media (min-width: ${TabletWidth}) {
    width: 180px;
    padding-bottom: 8px;
  }
`;

interface TimerProgressProps {
  $isActive: boolean;
  $timerSeconds: number;
  $isPaused: boolean;
}

export const TimerProgress = styled.div<TimerProgressProps>`
  display: block;
  height: 100%;
  color: ${ThemeColors.TassoBlue};
  background-color: ${ThemeColors.TassoViolet};
  animation: ${(props) => (props.$isActive ? `progressBar ${props.$timerSeconds}s linear` : undefined)};
  animation-fill-mode: both;
  animation-play-state: ${(props) => (props.$isPaused ? 'paused' : 'running')};

  @keyframes progressBar {
    0% {
      width: 3%;
    }
    100% {
      width: 100%;
    }
  }
`;

// Timestamp components
export const DateTimeWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 76px;

  @media (min-width: ${DesktopWidth}) {
    justify-content: flex-start;
    width: 80%;
  }
`;

export const CollectionTimeStampWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${ThemeColors.LightGray};
  border-radius: 16px;

  > :first-child {
    border-bottom: 1px solid ${ThemeColors.TassoWhite};
  }

  @media (min-width: ${DesktopWidth}) {
    max-width: 448px;
  }
`;

export const VideoTipWrapper = styled(TipWrapper)`
  @media (min-width: ${DesktopWidth}) {
    max-width: 370px;
  }
`;
