import React from 'react';

import NextButton from 'components/SharedComponents/NextButton';
import StepsProgressBar from 'components/SharedComponents/StepsProgressBar';
import { TypeInstructionPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  ContentWrapper,
  InstructionPanelContainer,
  InstructionsTipWrapper,
  InstructionTitle,
  InstructionWrapper,
} from './instructionPanel.styles';
import { TipIconStyles, TipIconWrapper, TipWithIconWrapper } from '../GenericStyles/generic.styles';

const InstructionPanel = (props: {
  panel: TypeInstructionPanelWithoutUnresolvableLinksResponse;
  phaseName: string;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  openHelpModal: () => void;
}) => {
  const { titleText, tips, instructions, nextButtonText } = props.panel.fields;
  const { openHelpModal } = props;

  return (
    <InstructionPanelContainer>
      <StepsProgressBar {...props} openHelpModal={openHelpModal} />
      <ContentWrapper>
        <ThemeFonts.DisplaySmall color={ThemeColors.TassoBlue} margin="0 0 32px 0">
          {titleText}
        </ThemeFonts.DisplaySmall>
        {instructions &&
          instructions.map((instruction, index: number) => (
            <InstructionWrapper key={index}>
              {instruction?.fields.icon && (
                <img src={instruction.fields.icon.fields.file?.url} alt={instruction.fields.icon.fields.description} />
              )}
              <InstructionTitle>
                {instruction?.fields.mainBoldText && (
                  <ThemeFonts.LabelLarge>{instruction.fields.mainBoldText}</ThemeFonts.LabelLarge>
                )}
                {instruction?.fields.mainNonBoldText && (
                  <ThemeFonts.BodyLarge>{instruction.fields.mainNonBoldText}</ThemeFonts.BodyLarge>
                )}
              </InstructionTitle>
              {instruction?.fields.secondaryText && (
                <ThemeFonts.BodyMedium>{instruction.fields.secondaryText}</ThemeFonts.BodyMedium>
              )}
            </InstructionWrapper>
          ))}
        {tips &&
          tips.map((tip, index: number) => (
            <InstructionsTipWrapper key={index}>
              <TipWithIconWrapper>
                {tip?.fields.tipIcon && (
                  <img src={tip.fields.tipIcon?.fields.file?.url} alt={tip.fields.tipIcon.fields.description} />
                )}
                <div>
                  {tip?.fields.titleIconPosition !== undefined && (
                    <TipIconWrapper $titleIconPosition={tip.fields.titleIconPosition}>
                      {tip.fields.titleText && <ThemeFonts.LabelLarge>{tip.fields.titleText}</ThemeFonts.LabelLarge>}
                      <TipIconStyles $titleIconPosition={tip.fields.titleIconPosition}>
                        {tip.fields.titleIcon?.fields.file?.url && tip.fields.titleIcon.fields.description && (
                          <img
                            src={tip.fields.titleIcon?.fields.file?.url}
                            alt={tip.fields.titleIcon.fields.description}
                          />
                        )}
                      </TipIconStyles>
                    </TipIconWrapper>
                  )}
                  {tip?.fields.textBody && <ThemeFonts.BodyMedium>{tip.fields.textBody}</ThemeFonts.BodyMedium>}
                </div>
              </TipWithIconWrapper>
            </InstructionsTipWrapper>
          ))}
      </ContentWrapper>

      <NextButton nextButtonText={nextButtonText} />
    </InstructionPanelContainer>
  );
};

export default InstructionPanel;
