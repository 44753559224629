import React from 'react';

import { DateTime } from 'luxon';
import BookmarkIconStatic from 'assets/BookmarkStaticIcon';
import BookmarkIconToggle from 'assets/BookmarkToggleIcon';
import ElipseIcon from 'assets/ElipseIcon';
import OpenTabIcon from 'assets/OpenTabIcon';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  LocationListItem,
  LocationListItemDetails,
  LocationListItemAvailabilityWrapper,
  LocationListItemAddressWrapper,
  LocationListItemLeft,
  LocationListItemRight,
  LocationListItemBookmarkIconWrapper,
  LocationListItemMapButtonWrapper,
  LocationListItemDistanceWrapper,
} from './dropOffLocation.styles';
import { CarrierBusinessHours, CarrierBusinessHoursConfiguration, CarrierLocation } from '../types';

export interface LocationProps {
  includeGoogleMapsLink: boolean;
  bookmarkingEnabled: boolean;
  activeBookmarkIndex: number;
  onClickBookmark: (locationIndex: number) => void;
}
interface props extends LocationProps {
  location: CarrierLocation;
}

const DropOffLocation = (props: props) => {
  const isLocationOpen = (currentDayHours: CarrierBusinessHoursConfiguration | null): boolean | null => {
    if (!currentDayHours?.opensAt || !currentDayHours.closesAt) {
      return null;
    }

    const now = DateTime.now();
    const opensAtDate = DateTime.fromFormat(currentDayHours.opensAt, 'TT');
    const closesAtDate = DateTime.fromFormat(currentDayHours.closesAt, 'TT');

    return opensAtDate <= now && now < closesAtDate;
  };

  const isBeforeLastCollectionTime = (currentDayHours: CarrierBusinessHoursConfiguration | null): boolean | null => {
    if (!currentDayHours?.lastDropOffAt) {
      return null;
    }

    const now = DateTime.now();
    const lastDropOffDate = DateTime.fromFormat(currentDayHours.lastDropOffAt, 'TT');

    return now < lastDropOffDate;
  };

  const formatDistance = (distance: number | null): string => {
    if (distance === null) {
      // This should never happen, just being cautious/paranoid.
      return '???';
    }

    const roundedToTwoDecimalPlaces = Math.round((distance + Number.EPSILON) * 100) / 100;

    return roundedToTwoDecimalPlaces.toString();
  };

  const getBookmarkIndex = () => {
    if (props.activeBookmarkIndex && props.activeBookmarkIndex !== -1) {
      return props.activeBookmarkIndex;
    }

    const lsBookmarkIndex = localStorage.getItem('activeBookmarkIndex');
    if (lsBookmarkIndex) {
      return parseInt(lsBookmarkIndex);
    }
  };

  const todaysHours =
    props.location.businessHours?.[DateTime.now().weekdayLong.toLowerCase() as keyof CarrierBusinessHours] ?? null;
  const isOpen = isLocationOpen(todaysHours);
  const isViableTime = isBeforeLastCollectionTime(todaysHours);
  const bookmarkIndex = getBookmarkIndex();

  return (
    <LocationListItem>
      <LocationListItemDetails>
        <LocationListItemLeft>
          <ThemeFonts.LabelLarge color={ThemeColors.TassoBlue}>{props.location.displayName}</ThemeFonts.LabelLarge>
          <LocationListItemAvailabilityWrapper data-testid="drop-off-location-availability">
            {isOpen !== null && isViableTime !== false && (
              <>
                <ThemeFonts.TitleSmall color={isOpen ? ThemeColors.TassoDarkBlue : ThemeColors.TassoHeritageRed}>
                  {isOpen ? 'Open Now' : 'Closed'}
                </ThemeFonts.TitleSmall>
                <ElipseIcon
                  height={8}
                  width={8}
                  color={isOpen ? ThemeColors.TassoDarkBlue : ThemeColors.TassoHeritageRed}
                />
              </>
            )}
            {isViableTime !== null && (
              <ThemeFonts.BodyMedium color={isViableTime ? ThemeColors.TassoDarkBlue : ThemeColors.TassoHeritageRed}>
                {isViableTime ? 'Last collection at ' : 'Last collection was at '}
                {DateTime.fromFormat(todaysHours!.lastDropOffAt!, 'TT').toLocaleString(DateTime.TIME_SIMPLE)}
              </ThemeFonts.BodyMedium>
            )}
          </LocationListItemAvailabilityWrapper>
          <LocationListItemAddressWrapper>
            <ThemeFonts.BodyMedium color={ThemeColors.HeavyGray}>{props.location.address1}</ThemeFonts.BodyMedium>
            {props.location.address2 && (
              <ThemeFonts.BodyMedium color={ThemeColors.HeavyGray}>{props.location.address2}</ThemeFonts.BodyMedium>
            )}
            <ThemeFonts.BodyMedium
              color={ThemeColors.HeavyGray}
            >{`${props.location.city}, ${props.location.district1} ${props.location.postalCode}`}</ThemeFonts.BodyMedium>
          </LocationListItemAddressWrapper>
        </LocationListItemLeft>
        <LocationListItemRight>
          <LocationListItemDistanceWrapper>
            <ThemeFonts.BodyMedium color={ThemeColors.TassoBlue}>
              {formatDistance(props.location.distanceMiles)}
            </ThemeFonts.BodyMedium>
            <ThemeFonts.BodyMedium color={ThemeColors.TassoBlue}>mi</ThemeFonts.BodyMedium>
          </LocationListItemDistanceWrapper>
          <LocationListItemBookmarkIconWrapper
            data-testid={`bookmark-${props.location.locationIndex}`}
            $selected={bookmarkIndex === props.location.locationIndex}
            disabled={!props.bookmarkingEnabled}
            onClick={props.bookmarkingEnabled ? () => props.onClickBookmark(props.location.locationIndex) : undefined}
          >
            {props.bookmarkingEnabled ? <BookmarkIconToggle /> : <BookmarkIconStatic />}
          </LocationListItemBookmarkIconWrapper>
        </LocationListItemRight>
      </LocationListItemDetails>
      {props.includeGoogleMapsLink && (
        <LocationListItemMapButtonWrapper
          // TODO:: (TD-4151) For now, this will work but it will show the address, not the location (e.g. "4468 Stone Way N" instead of "Walgreens")
          // we should be able to leverage Google's Geocoding API to improve this.
          href={`https://google.com/maps/place/${props.location.address1} ${props.location.city} ${props.location.district1} ${props.location.postalCode}`}
          target="_blank"
          rel="noreferrer"
        >
          <OpenTabIcon color={ThemeColors.TassoBrightBlue} />
          <ThemeFonts.LabelSmall color={ThemeColors.TassoDarkBlue}>Open in maps</ThemeFonts.LabelSmall>
        </LocationListItemMapButtonWrapper>
      )}
    </LocationListItem>
  );
};

export default DropOffLocation;
