import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { TabletWidth, ThemeColors, ThemeFonts } from 'styles/constants';

// Container
export const LocationPanelContainer = styled(PanelContainerStyles)`
  padding: 16px;
  justify-content: flex-start;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const LocationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-top: 24px;

  @media (min-width: ${TabletWidth}) {
    max-width: 520px;
    margin: 24px auto 0;
  }
`;

// Text
export const LocationPanelTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LocationPanelTextBodyWrapper = styled(ThemeFonts.BodyLarge)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${ThemeColors.TassoDarkBlue};

  p {
    margin: 0px;
  }
`;

// Location Container
export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
`;

// Dividing text
export const InputDividingText = styled(ThemeFonts.LabelLarge)`
  align-self: center;
`;

// Location detection
export const LocationDetectionButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  border: 2px solid ${(p) => (p.disabled ? ThemeColors.DarkGray : ThemeColors.TassoViolet)};
  background-color: ${(p) => (p.disabled ? `${ThemeColors.CloudyGray}99` : ThemeColors.TassoWhite)};
  align-items: center;
  font: inherit;
  border-radius: 86px;
  padding: 15px;
  gap: 10px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:disabled {
    background-color: ${ThemeColors.CloudyGray};
  }

  &:hover:enabled {
    cursor: pointer;
    background-color: ${ThemeColors.TassoLightViolet};
  }
`;
