import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';
import type { TypeInstructionSkeleton } from './TypeInstruction';
import type { TypeTipsSkeleton } from './TypeTips';

export interface TypeInstructionPanelFields {
  name: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Text;
  tips?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeTipsSkeleton>>;
  instructions: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeInstructionSkeleton>>;
  nextButtonText: EntryFieldTypes.Symbol;
}

export type TypeInstructionPanelSkeleton = EntrySkeletonType<TypeInstructionPanelFields, 'instructionPanel'>;
export type TypeInstructionPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeInstructionPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeInstructionPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeInstructionPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'instructionPanel';
}

export type TypeInstructionPanelWithoutLinkResolutionResponse = TypeInstructionPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeInstructionPanelWithoutUnresolvableLinksResponse = TypeInstructionPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeInstructionPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> = TypeInstructionPanel<
  'WITH_ALL_LOCALES',
  Locales
>;
export type TypeInstructionPanelWithAllLocalesAndWithoutLinkResolutionResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeInstructionPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeInstructionPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeInstructionPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
